import { useLDFlags } from 'Hooks/useLaunchDakly';
import React, { createElement, FC } from 'react';
import { IconType } from 'react-icons';
import { cn } from 'Utils/TailwindUtils';
import { useInboxContext } from './InboxDialog';

interface InboxDialogButtonProps {
  navigateToDiscover: () => void;
  text: string;
  icon: IconType;
  className?: string;
}

export const InboxDialogButton: FC<InboxDialogButtonProps> = ({ navigateToDiscover, icon, text, className }) => {
  const { sourcesToHide } = useLDFlags();
  const { source } = useInboxContext();
  if (sourcesToHide.includes(source)) return null;
  return (
    <div
      className={cn('mx-auto my-2 flex w-fit items-center gap-2 rounded-full border px-5 py-3 text-xs shadow-lg', className)}
      role="button"
      onClick={navigateToDiscover}
    >
      {createElement(icon, { className: 'size-4' })}
      <span>{text}</span>
    </div>
  );
};
