import { useLDFlags } from 'Hooks/useLaunchDakly';
import React from 'react';
import { AgentFullReportField, metricsMap } from './AgentFullReportField';

export const AgentFullReportData = ({ ticketData, analysisFields, stateData }) => {
  const AnalysisQuestions = useLDFlags().commentTypes;
  const AnalysisQuestionsObj: Record<string, { label: string; qaValue: string }> = JSON.parse(AnalysisQuestions);
  const AnalysisQuestionMap = new Map(Object.entries(AnalysisQuestionsObj));
  const metadata = ticketData?.metadata?.metadata;
  const validAnalysisFields = analysisFields.filter(({ commentType }) => {
    const metricName = metricsMap.get(commentType);
    return stateData.selectedSourceClientData?.es_alias?.metrics?.some(({ name }) => name === metricName);
  });
  return (
    <div className="mt-14 h-[75%] overflow-auto rounded-xl bg-blue-25 p-4">
      {validAnalysisFields.map(({ commentType }, index) => (
        <AgentFullReportField
          key={index}
          index={index}
          commentType={commentType}
          metadata={metadata}
          AnalysisQuestionMap={AnalysisQuestionMap}
          stateData={stateData}
        />
      ))}
    </div>
  );
};
