import { useEffect, useState, FC, useContext, createContext } from 'react';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { api } from 'Api';
import { resetConvdata, updateConversations } from 'Slices/timeseries';
import { updateSelectedConvViewTabId, updateIsConvViewTabDisplayed } from 'Slices/timeseries';
import { ADMIN_INDEX_SUPPORT_TYPES, SURVEY_TYPES } from 'Enums/SourceModelTypes';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { SummaryCardBase } from 'src/components/Card';
import type { IConversation } from 'Slices/types/timeseries';
import { useKey } from 'react-use';
import { ConversationData, ExtendedConversationData } from 'Pages/Discover/Inbox/types';
import { InboxLoader } from 'Pages/Discover/SkeletonPlaceholder';
import { InboxPane } from 'Pages/Discover/Inbox/InboxPane';
import { InboxView } from 'Pages/Discover/Inbox/InboxView';
import { useQuery } from '@tanstack/react-query';
import { getDrillDownFilter, useQuickInsight } from '../QuickInsightContainer';
import { InboxDialogButton } from './InboxDialogButton';
import { getSentisumTags } from 'src/api/Filters';
import { fetchConversations } from 'src/api/conversation';
import { ISelectedClientSourceData } from 'src/types/User';
import { InboxDialogHeader } from './InboxDialogHeader';
import { useLocation, useNavigate } from 'react-router';
import { useQuickSummaryDiginContext } from '../QuickSummaryDigInContext';
import { useSearchParams } from 'react-router-dom';
import { HiOutlineBookOpen, HiOutlineChatBubbleLeftEllipsis } from 'react-icons/hi2';
import { cloneDeep } from 'lodash';
import { FormatFiltersV4State, transformFilters } from 'src/api/Tranformers';
import { CommonFilterState } from 'Slices/types/common';
import { IChartIntervalBreakDown } from 'src/types/Filter';
import { IBookmarkFilter } from 'src/types/Bookmark';

export interface InboxDialogProps {
  navigateToDiscover: () => void;
  source: string;
  selectedSourceClientData: ISelectedClientSourceData;
}

interface IInboxContent {
  source: string;
  selectedSourceClientData: ISelectedClientSourceData;
  filters: CommonFilterState['filters'];
  chartIntervalBreakdown: IChartIntervalBreakDown;
  bookmarkFilters: IBookmarkFilter;
  showViewAllConversation: boolean;
}

export const InboxContext = createContext<IInboxContent>(null);

export const InboxDialog: FC<InboxDialogProps> = ({ navigateToDiscover, source, selectedSourceClientData }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeId, setActiveId] = useState(null);
  const [activeConversationIndex, setActiveConversationIndex] = useState(-1);
  const { selectedConvViewTabId, totalConversationCount, isConvViewTabDisplayed, convdata } = useAppSelector((state) => state.timeseries);
  const selectedDrillDownTopic = useAppSelector((state) => state.filter.selectedDrillDownTopic);
  const { selectedDimension, state, topicPayload } = useQuickInsight();
  const location = useLocation();
  const [_, setDigInState] = useQuickSummaryDiginContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const aspect = searchParams.get('aspect');
  const topicsQuery = useQuery({ queryKey: ['topics', source], queryFn: () => getSentisumTags(source) });

  const conversationDataQuery = useQuery({
    queryKey: ['fetchQuickInsightConversations', state, selectedDimension, selectedDrillDownTopic, aspect],
    queryFn: async () => {
      let updatedFilterState: FormatFiltersV4State = cloneDeep(state);
      if (selectedDrillDownTopic) {
        selectedSourceClientData?.es_alias?.integrations.forEach((integration) => {
          const key = integration.name;
          updatedFilterState.bookmarkFilters.datasourceFilters[key] = [
            ...(updatedFilterState.bookmarkFilters.datasourceFilters[key] || []),
            getDrillDownFilter(selectedDrillDownTopic),
          ];
        });
        updatedFilterState.bookmarkFilters.previousFilterRelation = 'and';
        updatedFilterState.filters.metadata = transformFilters(updatedFilterState.bookmarkFilters);
      }
      return fetchConversations({ ...topicPayload, aspect, state: updatedFilterState });
    },
  });

  useEffect(() => {
    if (conversationDataQuery?.data) dispatch(updateConversations(conversationDataQuery?.data));
  }, [conversationDataQuery?.data]);

  const isSentimentEnabled = () =>
    state.selectedSourceClientData?.es_alias?.enable_sentiments || SURVEY_TYPES.includes(state.selectedSourceClientData?.es_alias?.type);
  const closeConversationInfoView = () => {
    setActiveId((value) => {
      if (value) setActiveId(null);
      else {
        setSearchParams();
        navigate(location.pathname.split('/').slice(0, -1).join('/'));
        dispatch(resetConvdata());
        setDigInState({ summaryStatus: 'idle' });
      }
    });
  };
  const handleRead = (event, item, index) => {
    setActiveId(item.id);
    setActiveConversationIndex(index);
    if (!!event && event === 'click') {
      trackFilterEvent(TRACK_EVENT.OPEN_CONVERSATION, {
        Id: item?.id,
        Read: !item?.status?.read ? true : false,
      });
    }
    if (!item?.status?.read) {
      const status = { ...item.status, read: true };
      api.syncConversation(source, item.id, status).catch((e) => {});
    }
  };

  const navigateConversation = (navigationCount: number) => {
    if (!activeId) return;
    let index = activeConversationIndex;
    if (typeof navigationCount === 'number') {
      index += navigationCount;
    }
    if (convdata?.conversations && index >= 0 && index < convdata?.conversations?.length && activeConversationIndex !== index) {
      const item = convdata?.conversations[index];
      handleRead('click', item, index);
    }
  };

  const handleStar = (item: ExtendedConversationData, isStar: boolean) => {
    const status = { ...item.status, starred: isStar };
    api
      .syncConversation(source, item.id, status)
      .then(() => {
        trackFilterEvent(TRACK_EVENT.STAR_CONVERSATION, {
          Id: item.id,
          Star: isStar,
        });
      })
      .catch((e) => {});
  };

  const formatConv = (conv: IConversation) => {
    const { id, messages, sentisumTags, zedesktags, status, datatype, ...rest } = conv;
    const { ['Agent QA Score']: qaPreformanceScore, ...metadata } = rest.metadata;
    const result = {
      id,
      messages,
      date: conv[state.filters.selectedDateField] ?? conv.createdAt,
      tags: sentisumTags?.map((tag) => ({
        name: tag?.topic,
        sentiment: tag?.sentiment === 1 ? 'negative' : tag?.sentiment === 2 ? 'neutral' : 'positive',
      })),
      status: status,
      type: isSentimentEnabled() ? 'survey' : 'support',
      qaPreformanceScore,
      metadata: { ...rest, metadata },
    } as ConversationData;
    return result;
  };

  useEffect(() => {
    const updateViewTabId = (activeTabValue: 'ticket' | 'CSAT') => dispatch(updateSelectedConvViewTabId(activeTabValue));
    if (ADMIN_INDEX_SUPPORT_TYPES.includes(state.selectedSourceClientData?.es_alias?.type)) updateViewTabId('ticket');
    else updateViewTabId('CSAT');
  }, [activeId]);

  useKey('ArrowLeft', () => navigateConversation(-1), {}, [activeConversationIndex, activeId, convdata?.conversations?.length]);
  useKey('ArrowRight', () => activeConversationIndex < 19 && navigateConversation(1), {}, [
    activeConversationIndex,
    activeId,
    convdata?.conversations?.length,
  ]);
  useKey('Escape', closeConversationInfoView);

  const getConversationData = () => {
    const dataSet = convdata.conversations.find((conv) => conv.id === activeId);
    const dataValue: Partial<Record<'ticket' | 'CSAT', IConversation>> = {};
    if (dataSet?.csat_response) {
      if (!isConvViewTabDisplayed) dispatch(updateIsConvViewTabDisplayed(true));
      if (ADMIN_INDEX_SUPPORT_TYPES.includes(state.selectedSourceClientData?.es_alias?.type))
        if (selectedConvViewTabId === 'ticket') dataValue.ticket = dataSet;
        else dataValue.CSAT = dataSet.csat_response;
      else {
        if (selectedConvViewTabId === 'ticket') dataValue.ticket = dataSet.csat_response;
        else dataValue.CSAT = dataSet;
      }
    } else {
      dataValue.ticket = dataSet;
      if (isConvViewTabDisplayed) dispatch(updateIsConvViewTabDisplayed(false));
    }
    return {
      type: isSentimentEnabled() ? 'survey' : 'support',
      ticket: dataValue.ticket ? formatConv(dataValue.ticket) : undefined,
      CSAT: dataValue.CSAT ? formatConv(dataValue.CSAT) : undefined,
    } as const;
  };
  if (conversationDataQuery.isLoading || topicsQuery.isLoading || convdata?.conversations === undefined)
    return <InboxLoader className="mr-4 h-[91%]" />;
  return (
    <InboxContext.Provider
      value={{
        source,
        selectedSourceClientData,
        filters: state.filters,
        bookmarkFilters: state.bookmarkFilters,
        chartIntervalBreakdown: state.chartIntervalBreakdown,
        showViewAllConversation: false,
      }}
    >
      <div className="h-full space-y-3">
        <InboxDialogHeader
          activeId={activeId}
          totalCount={convdata.total}
          navigateConversation={navigateConversation}
          selectedConvViewTabId={selectedConvViewTabId}
          activeConversationIndex={activeConversationIndex}
          navigateToDiscover={navigateToDiscover}
          setActiveId={setActiveId}
          selectedDimension={selectedDimension}
        />
        <div className="h-[67vh] overflow-auto">
          <SummaryCardBase className="mr-4 h-full rounded-2xl p-0" data-sentry-mask>
            <div className="relative min-h-full min-w-full">
              <InboxPane
                items={convdata?.conversations.slice(0, 20)?.map((conv) => ({
                  ...formatConv(conv),
                  isStarred: conv.status.starred,
                  isRead: conv.status.read,
                }))}
                activeId={activeId}
                onStar={handleStar}
                onRead={handleRead}
                extra={
                  totalConversationCount > 19 && (
                    <InboxDialogButton navigateToDiscover={navigateToDiscover} icon={HiOutlineChatBubbleLeftEllipsis} text="View All Conversations" />
                  )
                }
                className="h-full"
              />
              {!!activeId && convdata?.conversations?.length > 0 && selectedConvViewTabId && (
                <InboxView
                  allTags={topicsQuery.data}
                  closeConversationInfoView={closeConversationInfoView}
                  isQuickInsight
                  {...getConversationData()}
                />
              )}
            </div>
          </SummaryCardBase>
          {totalConversationCount < 19 && (
            <InboxDialogButton navigateToDiscover={navigateToDiscover} icon={HiOutlineBookOpen} text="Go to Discover" className="my-6" />
          )}
        </div>
      </div>
    </InboxContext.Provider>
  );
};

export const useInboxContext = () => {
  const context = useContext(InboxContext);
  if (!context) throw new Error('useInboxContext must be used within QuickInsightData');
  return context;
};
