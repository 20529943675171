import React, { useEffect, useState } from 'react';
import { ISetState } from 'src/types/Globals';
import * as NestedDialog from 'src/components/Dialog/NestedDialog';
import { cn } from 'Utils/TailwindUtils';
import { Chat, ConversationData } from '../../types';
import { QaReport } from './QaReport';
import { AgentFullReport } from './QaAllConversation';
import { CloseButton } from 'Static/images';

export interface AgentQaContainerProps {
  open: boolean;
  handleChange: ISetState<boolean>;
  analysisFields: Chat[];
  ticketData: ConversationData;
}

export const AgentQaContainer = ({ open, handleChange, ticketData, analysisFields }: AgentQaContainerProps) => {
  const [currentScreen, setCurrentScreen] = useState<'QaReport' | 'FullReport'>('QaReport');

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (['ArrowRight', 'ArrowLeft'].includes(event.key)) {
        event.preventDefault();
        event.stopPropagation();
      }
    };
    const action = open ? 'addEventListener' : 'removeEventListener';
    document[action]('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [open]);

  const handleDialogToggle = () => {
    setCurrentScreen('QaReport');
    handleChange(!open);
  };

  return (
    <NestedDialog.Root open={open} onOpenChange={handleDialogToggle}>
      <NestedDialog.NestedDialogContent
        isOpen={open}
        className={cn('h-[78%] w-[70%] min-w-[44rem] rounded-xl p-8', { 'w-[60%]': currentScreen === 'FullReport' })}
        onKeyDownCapture={(event) => {
          if (event.key === 'Escape') {
            event.stopPropagation();
            handleDialogToggle();
          }
        }}
      >
        {currentScreen === 'QaReport' ? (
          <QaReport setCurrentScreen={setCurrentScreen} ticketData={ticketData} analysisFields={analysisFields} />
        ) : (
          <AgentFullReport setCurrentScreen={setCurrentScreen} ticketData={ticketData} analysisFields={analysisFields} />
        )}
        <NestedDialog.Close className="fixed -right-[1.5rem] -top-5">
          <CloseButton data-testid="close-quick-insight" />
        </NestedDialog.Close>
      </NestedDialog.NestedDialogContent>
    </NestedDialog.Root>
  );
};
