import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addHelpScoutCode,
  addTrustpilotName,
  addUsabillaCode,
  revokeIntegrationAccess,
  addGooglePlayStoreUrl,
  addAppleAppStoreUrl,
  addTypeformCode,
  addFreshdeskForm,
  addFreshchatForm,
  addYotpoAccessToken,
  addBazaarVoiceAccessToken,
  addFeefoAccessToken,
  addTrustedshopsAccessToken,
} from 'src/api/OAuth';
import { INTEGRATION_TYPES, REVOKE_INTEGRATION_ENDPOINTS } from 'Enums/IntegrationSources';
import { api } from '../api';
import { ServiceOAuthSliceType } from './types/oauth';

export const proceedToZendeskAccessToken = createAsyncThunk<any, any>('oauth/zendesk', ({ subdomain, code, csat = false }) => {
  return api.proceedToZendeskAccessToken(subdomain, code, csat);
});

export const proceedToZendeskChatAccessToken = createAsyncThunk<any, any>('oauth/zendesk-chat', ({ subdomain, code }) => {
  return api.proceedToZendeskChatAccessToken(subdomain, code);
});

export const proceedToSurveyMonkeyAccessToken = createAsyncThunk<any, any>('oauth/survey-monkey', ({ code }) => {
  return api.proceedToSurveyMonkeyAccessToken(code);
});

export const proceedToGorgiasAccessToken = createAsyncThunk<any, any>('oauth/gorgias', ({ subdomain, code }) => {
  return api.proceedToGorgiasAccessToken(subdomain, code);
});

export const proceedToDixaAccessToken = createAsyncThunk<any, any>('oauth/dixa', ({ accessToken }) => {
  return api.proceedToDixaAccessToken(accessToken);
});

export const proceedToIntercomAccessToken = createAsyncThunk<any, any>('oauth/intercom', ({ code }) => {
  return api.proceedToIntercomAccessToken(code);
});

export const proceedToHelpScoutAccessToken = createAsyncThunk<any, any>('oauth/helpscout', ({ code }) => {
  return addHelpScoutCode(code);
});

export const proceedTrustpilotAccessToken = createAsyncThunk<any, { businessURLs: string[] }>('oauth/trustpilot', ({ businessURLs }) => {
  return addTrustpilotName(businessURLs);
});

export const proceedYotpoAccessToken = createAsyncThunk<any, { app_key: string; secret_key: string; grant_type: string }>(
  'oauth/yotpo',
  ({ app_key, secret_key, grant_type }) => {
    return addYotpoAccessToken(app_key, secret_key, grant_type);
  }
);

export const proceedBazaarVoiceAccessToken = createAsyncThunk<any, { api_key: string }>('oauth/bazaarvoice', ({ api_key }) => {
  return addBazaarVoiceAccessToken(api_key);
});

export const proceedFeefoAccessToken = createAsyncThunk<any, { feefo_client_id: string; client_secret: string; email: string; grant_type: string }>(
  'oauth/feefo',
  ({ feefo_client_id, client_secret, email, grant_type }) => {
    return addFeefoAccessToken(feefo_client_id, client_secret, email, grant_type);
  }
);

export const proceedTrustedshopsoAccessToken = createAsyncThunk<
  any,
  { trustedshops_client_id: string; client_secret: string; email: string; grant_type: string }
>('oauth/trustedshops', ({ trustedshops_client_id, client_secret, email, grant_type }) => {
  return addTrustedshopsAccessToken(trustedshops_client_id, client_secret, email, grant_type);
});

export const revokeIntegrationAccessPermission = createAsyncThunk<any, any>('oauth/integration/revoke', (source) => {
  const sourceLabel = Object.keys(INTEGRATION_TYPES)[Object.values(INTEGRATION_TYPES).indexOf(source)];
  if (REVOKE_INTEGRATION_ENDPOINTS.hasOwnProperty(sourceLabel)) {
    const key = REVOKE_INTEGRATION_ENDPOINTS[sourceLabel];
    return revokeIntegrationAccess(key);
  }
});

export const proceedGooglePlayStoreAccessToken = createAsyncThunk('oauth/google-play-store', addGooglePlayStoreUrl);
export const proceedAppleAppStoreAccessToken = createAsyncThunk('oauth/apple-app-store', addAppleAppStoreUrl);
export const proceedFreshdeskFormAccessToken = createAsyncThunk('oauth/freshdesk', addFreshdeskForm);
export const proceedFreshchatFormAccessToken = createAsyncThunk('oauth/freshchat', addFreshchatForm);
export const proceedTypeformAccessToken = createAsyncThunk('oauth/typeform', addTypeformCode);
export const proceedToFacebookAccessToken = createAsyncThunk('oauth/facebook', api.proceedToFaceebokAccessToken);
export const getIntegrationServiceStatusList = createAsyncThunk('oauth/integration/all', api.getIntegrationServiceStatusList);
export const addNewGenericIntegration = createAsyncThunk('oauth/integration/generic', api.addNewGenericIntegration);
export const removeGenericIntegration = createAsyncThunk('oauth/integration/generic/remove', api.removeGenericIntegration);
export const updateGenericIntegration = createAsyncThunk('oauth/integration/generic/update', api.updateGenericIntegration);

export const proceedToUsabillaAccessToken = createAsyncThunk<any, { secretKey: string; accessKey: string }>(
  'oauth/usabilla',
  ({ accessKey, secretKey }) => {
    return addUsabillaCode(accessKey, secretKey);
  }
);
const initialState: ServiceOAuthSliceType = {
  getZendeskAccessTokenStatus: 'idle',
  getZendeskChatAccessTokenStatus: 'idle',
  getSurveyMonkeyAccessTokenStatus: 'idle',
  getGorgiasAccessTokenStatus: 'idle',
  getDixaAccessTokenStatus: 'idle',
  getIntercomAccessTokenStatus: 'idle',
  getHelpScoutAccessTokenStatus: 'idle',
  getTrustpilotAccessTokenStatus: 'idle',
  getYotpoAccessTokenStatus: 'idle',
  getBazaarVoiceAccessTokenStatus: 'idle',
  getFeefoAccessTokenStatus: 'idle',
  getTrustedshopsAccessTokenStatus: 'idle',
  getGooglePlayStoreAccessTokenStatus: 'idle',
  getFreshdeskFormAccessTokenStatus: 'idle',
  getFreshchatFormAccessTokenStatus: 'idle',
  getAppleAppStoreAccessTokenStatus: 'idle',
  getUsabillaAccessTokenStatus: 'idle',
  processFacebookAccessTokenStatus: 'idle',
  integrationServiceStatusObjectRequestStatus: 'idle',
  addNewGenericIntegrationStatus: 'idle',
  removeGenericIntegrationStatus: 'idle',
  updateGenericIntegrationStatus: 'idle',
  revokeIntegrationPermissionStatus: 'idle',
};
const ServicesOauthSlice = createSlice({
  name: 'oauth',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetDixaAccessTokenStatus: (state) => {
      state.getDixaAccessTokenStatus = 'idle';
    },
    resetRevokeIntegrationPermissionStatus: (state) => {
      state.revokeIntegrationPermissionStatus = 'idle';
    },
    resetGetTrustpilotAccessTokenError: (state) => {
      state.getTrustpilotAccessTokenError = '';
    },
    resetGetGooglePlayStoreAccessTokenError: (state) => {
      state.getGooglePlayStoreAccessTokenError = '';
    },
    resetGetFreshdeskFormAccessTokenError: (state) => {
      state.getFreshdeskFormAccessTokenError = '';
    },
    resetGetFreshchatFormAccessTokenError: (state) => {
      state.getFreshchatFormAccessTokenError = '';
    },
    resetGetAppleAppStoreAccessTokenError: (state) => {
      state.getAppleAppStoreAccessTokenError = '';
    },
    resetGetUsabillaAccessTokenError: (state) => {
      state.getUsabillaAccessTokenError = '';
    },
    resetProcessFacebookAccessTokenStatus: (state) => {
      state.processFacebookAccessTokenStatus = 'idle';
    },
    resetAddNewGenericIntegrationStatus: (state) => {
      state.addNewGenericIntegrationStatus = 'idle';
    },
    resetRemoveGenericIntegrationStatus: (state) => {
      state.removeGenericIntegrationStatus = 'idle';
    },
    resetUpdateGenericIntegrationStatus: (state) => {
      state.updateGenericIntegrationStatus = 'idle';
    },
    updateShowTypeformFormSelectionModal: (state, action: PayloadAction<boolean>) => {
      state.showTypeformFormSelectionModal = action.payload;
    },
    resetYotpoAccessTokenError: (state) => {
      state.getYotpoAccessTokenError = '';
    },
    resetBazaarVoiceAccessTokenError: (state) => {
      state.getBazaarVoiceAccessTokenError = '';
    },
    resetFeefoAccessTokenError: (state) => {
      state.getBazaarVoiceAccessTokenError = '';
    },
    resetTrustedshopsAccessTokenError: (state) => {
      state.getBazaarVoiceAccessTokenError = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(proceedToZendeskAccessToken.pending, (state) => {
        state.getZendeskAccessTokenStatus = 'pending';
      })
      .addCase(proceedToZendeskAccessToken.fulfilled, (state, action) => {
        state.getZendeskAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedToZendeskAccessToken.rejected, (state, action) => {
        state.getZendeskAccessTokenStatus = 'rejected';
      })
      .addCase(proceedToZendeskChatAccessToken.pending, (state) => {
        state.getZendeskChatAccessTokenStatus = 'pending';
      })
      .addCase(proceedToZendeskChatAccessToken.fulfilled, (state) => {
        state.getZendeskChatAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedToZendeskChatAccessToken.rejected, (state) => {
        state.getZendeskChatAccessTokenStatus = 'rejected';
      })
      .addCase(proceedToSurveyMonkeyAccessToken.pending, (state) => {
        state.getSurveyMonkeyAccessTokenStatus = 'pending';
      })
      .addCase(proceedToSurveyMonkeyAccessToken.fulfilled, (state) => {
        state.getSurveyMonkeyAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedToSurveyMonkeyAccessToken.rejected, (state) => {
        state.getSurveyMonkeyAccessTokenStatus = 'rejected';
      })
      .addCase(proceedToGorgiasAccessToken.pending, (state) => {
        state.getGorgiasAccessTokenStatus = 'pending';
      })
      .addCase(proceedToGorgiasAccessToken.fulfilled, (state) => {
        state.getGorgiasAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedToGorgiasAccessToken.rejected, (state) => {
        state.getGorgiasAccessTokenStatus = 'rejected';
      })
      .addCase(proceedToIntercomAccessToken.pending, (state) => {
        state.getIntercomAccessTokenStatus = 'pending';
      })
      .addCase(proceedToIntercomAccessToken.fulfilled, (state) => {
        state.getIntercomAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedToIntercomAccessToken.rejected, (state) => {
        state.getIntercomAccessTokenStatus = 'rejected';
      })
      .addCase(proceedToHelpScoutAccessToken.pending, (state) => {
        state.getHelpScoutAccessTokenStatus = 'pending';
      })
      .addCase(proceedToHelpScoutAccessToken.fulfilled, (state) => {
        state.getHelpScoutAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedToHelpScoutAccessToken.rejected, (state) => {
        state.getHelpScoutAccessTokenStatus = 'rejected';
      })
      .addCase(proceedTrustpilotAccessToken.pending, (state) => {
        state.getTrustpilotAccessTokenStatus = 'pending';
      })
      .addCase(proceedTrustpilotAccessToken.fulfilled, (state) => {
        state.getTrustpilotAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedTrustpilotAccessToken.rejected, (state, { error }) => {
        state.getTrustpilotAccessTokenStatus = 'rejected';
        state.getTrustpilotAccessTokenError = error.message;
      })
      .addCase(proceedYotpoAccessToken.pending, (state) => {
        state.getYotpoAccessTokenStatus = 'pending';
      })
      .addCase(proceedYotpoAccessToken.fulfilled, (state) => {
        state.getYotpoAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedYotpoAccessToken.rejected, (state, { error }) => {
        state.getYotpoAccessTokenStatus = 'rejected';
        state.getYotpoAccessTokenError = error.message;
      })
      .addCase(proceedBazaarVoiceAccessToken.pending, (state) => {
        state.getBazaarVoiceAccessTokenStatus = 'pending';
      })
      .addCase(proceedBazaarVoiceAccessToken.fulfilled, (state) => {
        state.getBazaarVoiceAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedBazaarVoiceAccessToken.rejected, (state, { error }) => {
        state.getBazaarVoiceAccessTokenStatus = 'rejected';
        state.getBazaarVoiceAccessTokenError = error.message;
      })
      .addCase(proceedFeefoAccessToken.pending, (state) => {
        state.getFeefoAccessTokenStatus = 'pending';
      })
      .addCase(proceedFeefoAccessToken.fulfilled, (state) => {
        state.getFeefoAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedFeefoAccessToken.rejected, (state, { error }) => {
        state.getFeefoAccessTokenStatus = 'rejected';
        state.getFeefoAccessTokenError = error.message;
      })
      .addCase(proceedTrustedshopsoAccessToken.pending, (state) => {
        state.getTrustedshopsAccessTokenStatus = 'pending';
      })
      .addCase(proceedTrustedshopsoAccessToken.fulfilled, (state) => {
        state.getTrustedshopsAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedTrustedshopsoAccessToken.rejected, (state, { error }) => {
        state.getTrustedshopsAccessTokenStatus = 'rejected';
        state.getTrustedshopsAccessTokenError = error.message;
      })
      .addCase(proceedGooglePlayStoreAccessToken.pending, (state) => {
        state.getGooglePlayStoreAccessTokenStatus = 'pending';
      })
      .addCase(proceedGooglePlayStoreAccessToken.fulfilled, (state) => {
        state.getGooglePlayStoreAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedGooglePlayStoreAccessToken.rejected, (state, { error }) => {
        state.getGooglePlayStoreAccessTokenStatus = 'rejected';
        state.getGooglePlayStoreAccessTokenError = error.message;
      })
      .addCase(proceedFreshdeskFormAccessToken.pending, (state) => {
        state.getFreshdeskFormAccessTokenStatus = 'pending';
      })
      .addCase(proceedFreshdeskFormAccessToken.fulfilled, (state) => {
        state.getFreshdeskFormAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedFreshdeskFormAccessToken.rejected, (state, { error }) => {
        state.getFreshdeskFormAccessTokenStatus = 'rejected';
        state.getFreshdeskFormAccessTokenError = error.message;
      })
      .addCase(proceedFreshchatFormAccessToken.pending, (state) => {
        state.getFreshchatFormAccessTokenStatus = 'pending';
      })
      .addCase(proceedFreshchatFormAccessToken.fulfilled, (state) => {
        state.getFreshchatFormAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedFreshchatFormAccessToken.rejected, (state, { error }) => {
        state.getFreshchatFormAccessTokenStatus = 'rejected';
        state.getFreshchatFormAccessTokenError = error.message;
      })
      .addCase(proceedAppleAppStoreAccessToken.pending, (state) => {
        state.getAppleAppStoreAccessTokenStatus = 'pending';
      })
      .addCase(proceedAppleAppStoreAccessToken.fulfilled, (state) => {
        state.getAppleAppStoreAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedAppleAppStoreAccessToken.rejected, (state, { error }) => {
        state.getAppleAppStoreAccessTokenStatus = 'rejected';
        state.getAppleAppStoreAccessTokenError = error.message;
      })
      .addCase(revokeIntegrationAccessPermission.fulfilled, (state) => {
        state.revokeIntegrationPermissionStatus = 'fulfilled';
      })
      .addCase(revokeIntegrationAccessPermission.rejected, (state) => {
        state.revokeIntegrationPermissionStatus = 'rejected';
      })
      .addCase(proceedToUsabillaAccessToken.pending, (state) => {
        state.getUsabillaAccessTokenStatus = 'pending';
      })
      .addCase(proceedToUsabillaAccessToken.fulfilled, (state) => {
        state.getUsabillaAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedToUsabillaAccessToken.rejected, (state, action) => {
        state.getUsabillaAccessTokenStatus = 'rejected';
        state.getUsabillaAccessTokenError = action.error.message;
      })
      .addCase(proceedToDixaAccessToken.pending, (state) => {
        state.getDixaAccessTokenStatus = 'pending';
      })
      .addCase(proceedToDixaAccessToken.fulfilled, (state) => {
        state.getDixaAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedToDixaAccessToken.rejected, (state) => {
        state.getDixaAccessTokenStatus = 'rejected';
      })
      .addCase(proceedToFacebookAccessToken.pending, (state) => {
        state.processFacebookAccessTokenStatus = 'pending';
        state.processFacebookErrorMessage = null;
      })
      .addCase(proceedToFacebookAccessToken.fulfilled, (state) => {
        state.processFacebookAccessTokenStatus = 'fulfilled';
      })
      .addCase(proceedToFacebookAccessToken.rejected, (state, action) => {
        state.processFacebookAccessTokenStatus = 'rejected';
        state.processFacebookErrorMessage = action?.error?.message;
      })
      .addCase(getIntegrationServiceStatusList.pending, (state) => {
        state.integrationServiceStatusObjectRequestStatus = 'pending';
      })
      .addCase(getIntegrationServiceStatusList.fulfilled, (state, action) => {
        state.integrationServiceStatusObject = action.payload.data;
        state.integrationServiceStatusObjectRequestStatus = 'fulfilled';
        state.getZendeskAccessTokenStatus = 'idle';
        state.getZendeskChatAccessTokenStatus = 'idle';
        state.getSurveyMonkeyAccessTokenStatus = 'idle';
        state.getGorgiasAccessTokenStatus = 'idle';
        state.getDixaAccessTokenStatus = 'idle';
        state.revokeIntegrationPermissionStatus = 'idle';
      })
      .addCase(getIntegrationServiceStatusList.rejected, (state) => {
        state.integrationServiceStatusObjectRequestStatus = 'rejected';
      })
      .addCase(addNewGenericIntegration.pending, (state) => {
        state.addNewGenericIntegrationStatus = 'pending';
      })
      .addCase(addNewGenericIntegration.fulfilled, (state) => {
        state.addNewGenericIntegrationStatus = 'fulfilled';
      })
      .addCase(addNewGenericIntegration.rejected, (state) => {
        state.addNewGenericIntegrationStatus = 'rejected';
      })
      .addCase(removeGenericIntegration.pending, (state) => {
        state.removeGenericIntegrationStatus = 'pending';
      })
      .addCase(removeGenericIntegration.fulfilled, (state) => {
        state.removeGenericIntegrationStatus = 'fulfilled';
      })
      .addCase(removeGenericIntegration.rejected, (state) => {
        state.removeGenericIntegrationStatus = 'rejected';
      })
      .addCase(updateGenericIntegration.pending, (state) => {
        state.updateGenericIntegrationStatus = 'pending';
      })
      .addCase(updateGenericIntegration.fulfilled, (state) => {
        state.updateGenericIntegrationStatus = 'fulfilled';
      })
      .addCase(updateGenericIntegration.rejected, (state) => {
        state.updateGenericIntegrationStatus = 'rejected';
      });
  },
});

export const {
  resetDixaAccessTokenStatus,
  resetAddNewGenericIntegrationStatus,
  resetRemoveGenericIntegrationStatus,
  resetUpdateGenericIntegrationStatus,
  resetRevokeIntegrationPermissionStatus,
  resetProcessFacebookAccessTokenStatus,
  resetGetGooglePlayStoreAccessTokenError,
  resetGetFreshdeskFormAccessTokenError,
  resetGetFreshchatFormAccessTokenError,
  resetYotpoAccessTokenError,
  resetBazaarVoiceAccessTokenError,
  resetFeefoAccessTokenError,
  resetTrustedshopsAccessTokenError,
  resetGetAppleAppStoreAccessTokenError,
  resetGetTrustpilotAccessTokenError,
  resetGetUsabillaAccessTokenError,
  updateShowTypeformFormSelectionModal,
} = ServicesOauthSlice.actions;
export default ServicesOauthSlice.reducer;
