import { ConversationData } from 'Pages/Discover/Inbox/types';
import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useSearchParams } from 'react-router-dom';
import { updateChartDimensionType } from 'Slices/filter';
import { useAppDispatch } from 'src/core/store';
import { useInboxContext } from 'src/ui/containers/QuickInsight/Conversations';

export const AgentFullPerformanceScore: FC<{
  metadata: Record<string, string | Object>;
  totalConversationCount?: number;
  agentScore?: number;
}> = ({ metadata, totalConversationCount, agentScore }) => {
  const dispatch = useAppDispatch();
  const { showViewAllConversation } = useInboxContext();
  const [_, setSearchParams] = useSearchParams();
  const agent: string = (metadata?.agent_name as string) ?? (metadata?.Email as string);
  const handleViewAllConversations = async () => {
    await dispatch(updateChartDimensionType(metadata?.agent_name ? 'agent_name' : 'Agent Email'));
    setSearchParams({ topic: agent as string });
  };
  const formatNumberWithCommas = (number: number): string => new Intl.NumberFormat('en-US').format(number);

  return (
    <>
      <div className="text-sm font-semibold leading-tight text-indigo-600">
        {showViewAllConversation && (
          <span role="button" onClick={handleViewAllConversations}>
            View all conversations of <span>{agent?.length <= 18 ? agent : agent.slice(0, 18) + '...'}</span> &rarr;
          </span>
        )}
      </div>
      <div className="absolute right-0 top-10 flex w-96 justify-between gap-4 rounded-xl border border-gray-200 bg-indigo-25 px-5 py-3">
        <div>
          <span className="text-sm font-bold">Overall Performance Score</span>
          <br />
          <div className="pt-1 text-sm font-normal">
            for total of{' '}
            <span className="inline-block font-bold">
              {totalConversationCount !== undefined ? formatNumberWithCommas(totalConversationCount) : <Skeleton width={30} />}
            </span>{' '}
            Conversations
          </div>
        </div>
        <div className="w-28 truncate text-right font-sherika text-4xl font-bold tracking-tight text-indigo-600">
          {agentScore !== undefined ? `${agentScore}%` : <Skeleton width={50} />}
        </div>
      </div>
    </>
  );
};
