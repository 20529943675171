import React, { FC } from 'react';
import { AgentProfileCard } from '../AgentProfileCard';
import { FiArrowLeft } from 'react-icons/fi';
import { AgentFullReportData } from './AgentFullReportData';
import { useQuery } from '@tanstack/react-query';
import { getSummaryOverviewGraphData } from 'src/api/Summary';
import { SOURCE_TYPES } from 'Utils/enums/SourceModelTypes';
import { cloneDeep } from 'lodash';
import { transformFilters } from 'src/api/Tranformers';
import { AgentFullPerformanceScore } from './AgentFullPerformanceScore';
import { QaReportProps } from '../QaReport/QaReport';
import { useInboxContext } from 'src/ui/containers/QuickInsight/Conversations';

const AgentQaScoreMetric = {
  name: 'percentage_agent_performance_quality' as SOURCE_TYPES,
  display_name: 'Agent QA score',
  path: 'metadata.agent_performance',
  is_order_reverse: false,
};

const agentNamedimension = {
  name: 'agent_name',
  display_name: 'Agent Name',
  path: 'metadata.agent_name.keyword',
};

const agentEmailDimension = { name: 'Agent Email', display_name: 'Agent email', path: 'metadata.Email.keyword' };

export const AgentFullReport: FC<QaReportProps> = ({ setCurrentScreen, ticketData, analysisFields }) => {
  const { bookmarkFilters, chartIntervalBreakdown, selectedSourceClientData, filters } = useInboxContext();
  let updatedFilterState = cloneDeep(bookmarkFilters);
  selectedSourceClientData?.es_alias?.integrations.forEach((integration) => {
    const key = integration.name;
    updatedFilterState.datasourceFilters[key] = [
      {
        conditionalFilterRelation: 'or',
        conditionalFilters: [
          {
            type: 'metadata',
            fieldName: ticketData?.metadata?.metadata?.agent_name ? 'agent_name.keyword' : 'Email.keyword',
            valuesRelation: 'or',
            values: [(ticketData?.metadata?.metadata?.agent_name as string) ?? (ticketData?.metadata?.metadata?.Email as string)],
          },
        ],
      },
    ];
  });
  updatedFilterState.previousFilterRelation = 'and';

  const stateData = {
    chartDimensionObject: ticketData?.metadata?.metadata?.agent_name ? agentNamedimension : agentEmailDimension,
    chartMetricObject: AgentQaScoreMetric,
    selectedSourceClientData,
    filters: { ...filters, metadata: transformFilters(updatedFilterState) },
    chartIntervalBreakdown,
  };

  const performaceScoreMutation = useQuery({
    queryKey: ['performaceScoreMutation', filters.dateRange, ticketData?.metadata?.metadata?.agent_name, ticketData?.metadata?.metadata?.Email],
    queryFn: () => getSummaryOverviewGraphData({}, stateData, false),
  });

  return (
    <>
      <div className="relative flex justify-between">
        <div className="flex gap-5">
          <div
            className=" flex h-9 w-9 items-center justify-center rounded-full  bg-white shadow-[0px_4px_8px_0px_rgba(20,27,63,0.10)]"
            role="button"
            onClick={() => setCurrentScreen('QaReport')}
          >
            <FiArrowLeft className="text-lg" />
          </div>
          <div>
            <h2 className="text-2xl font-bold">Agent QA</h2>
            <AgentProfileCard
              className="mt-2.5"
              showTooltip={true}
              agent={(ticketData?.metadata?.metadata?.agent_name as string) ?? (ticketData?.metadata?.metadata?.Email as string)}
            />
          </div>
        </div>
        <AgentFullPerformanceScore
          metadata={ticketData?.metadata?.metadata}
          totalConversationCount={performaceScoreMutation?.data?.data['percentage_agent_performance_quality'].current_interval?.metrics[1]?.value}
          agentScore={performaceScoreMutation?.data?.data['percentage_agent_performance_quality'].current_interval?.metrics[0]?.value}
        />
      </div>
      <AgentFullReportData ticketData={ticketData} analysisFields={analysisFields} stateData={stateData} />
    </>
  );
};
