import { useEffect, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './core/store';
import { usePrevious } from 'Hooks';
import { fetchUser } from 'Slices/auth';
import { updateSelectedSourceClientData, updateChartComparatorSelector, updateChartMetricSelector } from 'Slices/filter';
import { PageSpinner } from './components/Spinner';
import AppRouter from './AppRouter'; // breaking if moved up
import { TRACK_EVENT, intializeTrackers, trackEvent } from 'src/core/trackEvent';
import { ISelectedClientSourceData, IUser } from './types/User';
import { SOURCE_TYPES, SURVEY_TYPES } from 'Enums/SourceModelTypes';
import * as Sentry from '@sentry/react';
import { useRefreshNotification, useTrackPageTme } from 'Hooks';
import { enableHubspotTracking } from './core/hubspotTracking';
import { useWhiteLabel } from './context/WhiteLabelContext';
import moment from 'moment';
import { logout } from 'Utils/auth';
import { useLaunchDakly } from 'Hooks/useLaunchDakly';
import { setDefaultSourceAction } from 'Slices/actions/launchdarkly';
const chmln = require('@chamaeleonidae/chmln');

let isUserLoggedIn = false;

const App = () => {
  const dispatch = useAppDispatch();
  const ldClient = useLaunchDakly();
  const auth = useAppSelector((state) => state.auth);
  const { user, isAuthenticated, loginSucceeded, userAPIStatus } = auth;
  const prevUser = usePrevious(user);
  const isWhiteLabel = useWhiteLabel().isWhiteLabel;
  const filters = useAppSelector((state) => state.filter.filters);
  const selectedSourceClientData = useAppSelector((state) => state.filter.selectedSourceClientData);
  const source = filters?.source;
  const previousSource = usePrevious(source);

  const [loading, setLoading] = useState(true);

  const loadUser = useCallback(async () => {
    if (userAPIStatus === 'pending') return;
    const result = await dispatch(fetchUser());
    if (result.meta.requestStatus === 'fulfilled') {
      const user = result.payload['user'] as IUser;
      isWhiteLabel || enableHubspotTracking(user.email);
      const ldFlags = await ldClient.identifyUser(user, { custom: { source } });
      dispatch(setDefaultSourceAction(ldFlags.defaultEsAlias, ldFlags.sourcesToHide));
    } else if (result.meta.requestStatus === 'rejected') logout();
  }, [userAPIStatus]);

  useRefreshNotification();
  useTrackPageTme();

  useEffect(() => {
    if (isAuthenticated) loadUser().finally(() => setLoading(false));
    else setLoading(false);
  }, []);

  useEffect(() => {
    // when user logs in
    if (loginSucceeded) {
      isUserLoggedIn = true;
      loadUser();
    }
  }, [loginSucceeded]);

  useEffect(() => {
    if (!prevUser && user) intializeTrackers();
    if (isUserLoggedIn) {
      isUserLoggedIn = false;
      trackEvent(TRACK_EVENT.LOGIN);
    }
    if (user) {
      Sentry.setUser({ id: user.uuid, email: user.email, username: user.name, segment: user.on_trial ? 'trial' : 'paid' });
      chmln.identify(user.uuid, {
        email: user.email,
        user: user.name,
        last_seen_by_dashboard: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      });
    }
  }, [user]);

  useEffect(() => {
    if (source && (source !== previousSource || !selectedSourceClientData?.es_alias?.name)) {
      let selectedClientSourceData: ISelectedClientSourceData;
      user?.clients?.forEach(({ es_aliases, ...sourceData }) => {
        if (es_aliases?.some(({ name }) => name === source))
          selectedClientSourceData = { ...sourceData, es_alias: es_aliases.find(({ name }) => name === source) };
      });
      dispatch(updateSelectedSourceClientData(selectedClientSourceData));
      if (selectedClientSourceData?.es_alias?.name) {
        if (SURVEY_TYPES.includes(selectedClientSourceData?.es_alias?.type)) {
          dispatch(updateChartComparatorSelector('absolute'));
          dispatch(updateChartMetricSelector(SOURCE_TYPES.SURVEY_TYPE));
        } else {
          dispatch(updateChartComparatorSelector('percent'));
          dispatch(updateChartMetricSelector(SOURCE_TYPES.SUPPORT_TYPE));
        }
      }
    }
  }, [source, user]);

  return !loading ? <AppRouter /> : <PageSpinner />;
};

export default App;
