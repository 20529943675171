import React, { FC, useState } from 'react';
import { RelevantDriverItem } from './RelevantDriverItem';
import { DialogSpinner } from 'src/components/Dialog/DialogSpinner';
import { useQuery } from '@tanstack/react-query';
import { getDrillDownCounts } from 'src/api/Summary';
import { RelevantDriverContainer } from './RelevantDriverContainer';
import { VOLUME_METRIC_OBJECT } from 'Utils/enums/ChartMetricObjects';
import { SENTISUM_TAG_TYPES } from 'Utils/constants/common';
import { SelectTheme } from './SelectTheme';
import { getThemes } from 'src/api/Theme';
import { useLocation } from 'react-router';
import { useDrillDownContext } from 'src/ui/containers/QuickInsight/DrillDown';
import { DrillDownData } from 'src/types/DrillDown';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { resetConvdata } from 'Slices/timeseries';
import { updateDrillDownTopic } from 'Slices/filter';

interface RelevantDriversDataProps {
  theme: string;
  setTheme: (theme: string) => void;
}

export const RelevantDriversData: FC<RelevantDriversDataProps> = ({ theme, setTheme }) => {
  const location = useLocation();
  const { dimension, selectedClientSourceData, filterState, chartDimensionObject, selectedTopic, chartComparator } = useDrillDownContext();
  const { selectedDrillDownTopic } = useAppSelector((state) => state.filter);
  const dispatch = useAppDispatch();
  const [showAll, setShowAll] = useState(false);
  const themesQuery = useQuery({
    queryKey: ['themes', selectedClientSourceData.es_alias.name],
    queryFn: () => getThemes(selectedClientSourceData.es_alias.name),
  });
  const getDrillDownQuery = useQuery({
    queryKey: ['getDrillDownData', dimension.name, selectedTopic, filterState.filters.dateRange, filterState.bookmarkFilters],
    queryFn: () =>
      getDrillDownCounts(
        dimension,
        VOLUME_METRIC_OBJECT,
        chartDimensionObject,
        selectedTopic,
        !location.pathname.includes('discover') ? filterState : null
      ),
  });
  let relevantDriverData = getDrillDownQuery?.data?.drill_downs.sort((a, b) => b.metric.value - a.metric.value);
  if (theme) {
    const selectedTheme = themesQuery?.data?.find((t) => t.name === theme);
    relevantDriverData = relevantDriverData?.filter((data) => Object.values(selectedTheme.dimension_mapping)[0].includes(data.name));
  }
  const handleDrillDownTopic = (topic: DrillDownData) => {
    dispatch(resetConvdata());
    if (selectedDrillDownTopic?.topic === topic.name) dispatch(updateDrillDownTopic(null));
    else dispatch(updateDrillDownTopic({ topic: topic.name, dimension: dimension }));
  };

  const driversLength = relevantDriverData?.length;
  if (!showAll) relevantDriverData = relevantDriverData?.slice(0, 5);
  return (
    <RelevantDriverContainer driversLength={driversLength} showAll={showAll} setShowAll={setShowAll}>
      {getDrillDownQuery.isLoading || themesQuery.isLoading ? (
        <DialogSpinner message="Loading..." />
      ) : (
        <>
          {(dimension.name === SENTISUM_TAG_TYPES.SENTISUM_TAG || dimension.name === SENTISUM_TAG_TYPES.DIY_TAG) && (
            <SelectTheme theme={theme} selectTheme={setTheme} themes={themesQuery?.data} dimensionName={dimension.name} />
          )}
          {relevantDriverData?.length === 0 ? (
            <div className="flex h-32 items-center justify-center text-gray-600" data-testid="drill-down-data">
              No Data
            </div>
          ) : (
            relevantDriverData?.map((data) => (
              <RelevantDriverItem
                metric={data.metric}
                name={data.name}
                onNameClick={() => handleDrillDownTopic(data)}
                highestValue={relevantDriverData[0].metric.value}
                key={data.name}
                chartComparator={chartComparator}
              />
            ))
          )}
        </>
      )}
    </RelevantDriverContainer>
  );
};
