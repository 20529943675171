import { WhiteLabelConfig } from 'src/types/WhiteLabel';
import { getCdnClientInstance } from './utils/clientAdapters';

export const getWhiteLabelSubdomains = async () => {
  const key = 'subdomains.json';
  return (await getCdnClientInstance(key).get<string[]>(key)).data;
};
export const getWhiteLabelConfig = async (domain: string) => {
  const key = domain.concat('/config.json');
  return (await getCdnClientInstance(key).get<WhiteLabelConfig>(key)).data;
};

export const getDepopQuestions = async () => {
  const key = 'depop-questions.json';
  return (await getCdnClientInstance(key).get<{ topic: string; questions: string[] }[]>(key)).data;
};
