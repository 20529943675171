import { render } from 'react-dom';
import App from './App';
import { initScripts } from './core/utils';
import GlobalContext from './context';
import './index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { PostHogProvider } from 'posthog-js/react';
import { initPosthog } from './context/posthog';

initScripts();

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    options: { bootstrap: 'localStorage' },
  });
  render(
    <LDProvider>
      <GlobalContext>
        <PostHogProvider client={initPosthog()}>
          <App />
        </PostHogProvider>
      </GlobalContext>
    </LDProvider>,
    document.getElementById('root')
  );
})();
