import { checkConditions } from 'Utils';
import React from 'react';

export const getCurrentValue = (value, metricMetadata) => {
  let { prefixText, value: valueData } = checkConditions.getChartValueDisplayProperty(value, metricMetadata);
  return prefixText + valueData + (metricMetadata?.IS_PERCENT_SYMBOL_NEED ? '%' : '');
};

export const CustomTick = (props) => {
  const { x, y, payload, metricMetadata, isVerticalBarGraph, isSentimentMetric } = props;
  const newX = x - (isVerticalBarGraph ? 30 : 0);
  if (isSentimentMetric && payload.value > 100) return null;
  return (
    <text x={newX} y={y} fill="#6A6A6A" textAnchor="start" className="text-xs">
      {getCurrentValue(payload.value, metricMetadata)}
    </text>
  );
};
