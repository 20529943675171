import { store } from 'src/core/store';
import { ReportType } from 'src/types/Report';
import { formatFiltersV4 } from './Filter';
import { JSONContent } from '@tiptap/react';
import { CommonFilterState } from 'Slices/types/common';
import { SelectedVisualizationType } from 'src/types/enums';
import { MetricReportConfigration } from '../apiTypes/Report';
import { EditMetricCardState } from 'Slices/editMetricCard';

const getAllGraphReportData = (
  dashboardId: number,
  name: string,
  report_type: ReportType,
  state: CommonFilterState,
  visualization_type?: SelectedVisualizationType,
  driverCount?: number,
  description?: string
) => {
  const { chartComparator } = state;
  const { source, dimension, metrics, comparisons, conditions } = formatFiltersV4({ chartComparator }, state);
  const { interval, ...conditionsWithoutInterval } = conditions;
  return {
    dashboard_id: dashboardId,
    name,
    description,
    configuration: {
      report_type: report_type,
      ...(driverCount && { dimension_limit: driverCount }),
      source,
      visualization_type,
      dimension,
      metrics,
      comparisons,
      conditions: conditionsWithoutInterval,
      sortOn: report_type === 'L' ? { field: metrics[0].name, order: 'DESC' } : undefined,
    },
  };
};

export const getGraphReportData = (dashboardId: number) => {
  const state = store.getState().editGraphReport;
  const name = store.getState().editReport.name;
  const description = store.getState().editReport.description;
  const { visualizationType, tableVisualization, selectedVisualizationType, driverItems } = state;
  const report_type: ReportType = visualizationType === 'overTime' ? 'LG' : tableVisualization;
  const driverCount =
    driverItems.show && visualizationType === 'summary' && (tableVisualization === 'H' || tableVisualization === 'L')
      ? driverItems.driverCount
      : null;
  return getAllGraphReportData(dashboardId, name, report_type, state, selectedVisualizationType, driverCount, description);
};

export const getSummaryGraphReportData = (dashboardId: number, name: string, reportType: ReportType) => {
  const state = store.getState().filter;
  return getAllGraphReportData(dashboardId, name, reportType, state, undefined, undefined);
};

export const getDocReportData = (dashboardId: number, data?: JSONContent) => ({
  dashboard_id: dashboardId,
  name: 'Untitled',
  configuration: {
    report_type: 'DOC',
    data,
  },
});

export const getMetricCardsReportData = (dashboardId: number, cards: EditMetricCardState['metricCards']) => {
  const name = store.getState().editReport.name;
  const description = store.getState().editReport.description;
  return {
    dashboard_id: dashboardId,
    name,
    description,
    configuration: {
      report_type: 'MC',
      metric_cards: cards.map(({ id, ...card }) => card),
    },
  };
};
