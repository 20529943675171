import React, { FC } from 'react';
import { Summary } from 'Static/images';
import { ConversationAccordionTab } from '../../ConversationAccordionTab';
import * as Accordion from '@radix-ui/react-accordion';
import { QaReportFields } from './QaReportFields';
import { QAReportHeader } from './QAReportHeader';
import { Chat, ConversationData } from '../../../types';
import { ISetState } from 'src/types/Globals';
import { AgentPerformanceRow } from './AgentPerformanceRow';

export interface QaReportProps {
  setCurrentScreen: ISetState<'QaReport' | 'FullReport'>;
  ticketData: ConversationData;
  analysisFields: Chat[];
}

export const QaReport: FC<QaReportProps> = ({ setCurrentScreen, ticketData, analysisFields }) => {
  const agentFieldsToShow = [
    'Conversation Resolved',
    'Agent Resolution',
    'Opening',
    'Closing',
    'Agent Empathy',
    'Agent Friendliness',
    'Agent Knowledge',
    'Agent Professionalism',
    'Agent Understanding',
    'Agent Confidence',
    'Agent Personalization',
    'Agent Clarity',
    'Tone of Voice',
    'DPA',
  ];
  const improvementText =
    analysisFields.find((field) => field.commentType === 'agentimprovementSuggestion')?.message ||
    analysisFields.find((field) => field.commentType === 'suggestedImprovement')?.message;
  return (
    <>
      <QAReportHeader
        agent={(ticketData?.metadata?.metadata?.agent_name as string) ?? (ticketData?.metadata?.metadata?.Email as string)}
        setCurrentScreen={setCurrentScreen}
        ticketId={Number(ticketData.id)}
      />
      <div className="mt-2.5 flex h-full">
        <div className="mr-5 h-[82%] w-3/5 space-y-3 overflow-auto">
          {improvementText && (
            <div className="rounded-xl border border-gray-200 text-sm">
              <Accordion.Root className="space-y-3" type="multiple" defaultValue={['summary']}>
                <ConversationAccordionTab className="gap-4" value="summary" label={'Suggested Improvements'} icon={<Summary className="h-4 w-4" />}>
                  <p className="mt-3 text-sm text-gray-600">{improvementText}</p>
                </ConversationAccordionTab>
              </Accordion.Root>
            </div>
          )}
          <QaReportFields ticketData={ticketData} analysisFields={analysisFields} />
        </div>
        <div className="flex max-h-[82%] w-2/5 flex-col rounded-xl border border-gray-200">
          <div className="flex h-20 justify-between rounded-t-xl bg-indigo-50 p-4">
            <p className="flex items-center text-sm font-bold">Overall Performance Score</p>
            <p className="text-4xl font-extrabold text-indigo-600">
              {Number.parseFloat(ticketData?.qaPreformanceScore?.split('%')[0])?.toFixed() + '%'}
            </p>
          </div>
          <div className="flex-1 overflow-auto p-4">
            {agentFieldsToShow.map((item, index) => (
              <>
                {ticketData?.metadata?.metadata?.[item] ? (
                  <AgentPerformanceRow
                    key={index}
                    item={item}
                    score={(ticketData?.metadata?.metadata?.[item] as string) || 'NA'}
                    isLast={index === agentFieldsToShow.length - 1}
                  />
                ) : (
                  <></>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
