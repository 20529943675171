import React, { FC } from 'react';
import { HiSparkles } from 'react-icons/hi2';

interface QuestionProps {
  question: string;
  selectQuestion: (question: string) => void;
}

export const Question: FC<QuestionProps> = ({ question, selectQuestion }) => {
  return (
    <button className="group flex items-center gap-4 text-sm" onClick={() => selectQuestion(question)} data-testid="digin-question-select">
      <div className="grid h-8 w-8 shrink-0 place-content-center rounded-full border bg-gray-650 text-base text-gray-790 group-hover:text-blue-1100">
        Q
      </div>
      <div className="text-left text-gray-500 group-hover:text-black">{question}</div>
      <button className="invisible flex shrink-0 items-center gap-2 rounded-3xl bg-indigo-50 px-3 py-1 text-indigo-600 group-hover:visible">
        <HiSparkles />
        <span>Get answer</span>
      </button>
    </button>
  );
};
