import React from 'react';
import { cn } from 'Utils/TailwindUtils';

interface AgentPerformanceRowProps {
  item: string;
  score: string;
  isLast?: boolean;
}

const getLabelAndColor = (score: string) => {
  if (score === 'na') return { label: 'NA', colorClass: 'bg-gray-100 text-gray-600', percentageScore: '-' };
  const scoreValue = score === 'poor' || score === 'no' ? 0 : score === 'average' ? 0.5 : score === 'excellent' || score === 'yes' ? 1 : 0;

  const percentageScore = (scoreValue * 100).toFixed(0) + '%';

  if (score === 'yes') return { label: 'Yes', colorClass: 'bg-green-100 text-green-350', percentageScore };
  if (score === 'no') return { label: 'No', colorClass: 'bg-red-100 text-red-800', percentageScore };

  if (scoreValue === 0) return { label: 'Poor', colorClass: 'bg-red-100 text-red-800', percentageScore };
  if (scoreValue === 0.5) return { label: 'Average', colorClass: 'bg-warning-100 text-warning-800', percentageScore };
  if (scoreValue === 1) return { label: 'Excellent', colorClass: 'bg-green-100 text-green-350', percentageScore };

  return { label: 'NA', colorClass: 'bg-gray-100 text-gray-600', percentageScore };
};

export const AgentPerformanceRow: React.FC<AgentPerformanceRowProps> = ({ item, score, isLast }) => {
  const { label, colorClass, percentageScore } = getLabelAndColor(score);

  return (
    <div className={cn('mx-5 flex justify-between py-6', { 'border-b border-dashed border-gray-200': !isLast })}>
      <p className="flex items-center text-sm">{item === 'Conversation Resolved' ? 'Conversation Resolution' : item}</p>
      <div className="flex items-center gap-2">
        <p className={`rounded-lg px-1.5 py-1 text-sm ${colorClass}`}>{label}</p>
        <p className="rounded-lg bg-indigo-25 px-1.5 py-1 text-sm text-indigo-600">{percentageScore}</p>
      </div>
    </div>
  );
};
