import React, { FC } from 'react';
import { cn } from 'Utils/TailwindUtils';
import { QAField } from './QaReportFields';

interface AgentQaQuestionAnswerProps {
  qaFields: QAField[];
}

export const AgentQaQuestionAnswer: FC<AgentQaQuestionAnswerProps> = ({ qaFields }) => {
  const scoreGroups = {
    red: ['poor', 'no'],
    green: ['excellent', 'yes'],
    gray: ['na'],
    yellow: ['average'],
  };

  const colorMap = {
    red: { bg: 'bg-red-25', text: 'text-red-550' },
    green: { bg: 'bg-green-25', text: 'text-green-350' },
    gray: { bg: 'bg-gray-100', text: 'text-gray-500' },
    yellow: { bg: 'bg-warning-25', text: 'text-warning-800' },
  };

  return (
    <div className="mt-5 space-y-6">
      {qaFields.map((item, index) => {
        const scoreValue = typeof item.score === 'object' && item.score !== null ? item.score.score : item.score;
        if (!scoreValue) return null;
        const normalizedScore = String(scoreValue).toLowerCase();
        const colorGroup = scoreGroups?.red?.includes(normalizedScore)
          ? 'red'
          : scoreGroups?.green?.includes(normalizedScore)
          ? 'green'
          : scoreGroups?.gray?.includes(normalizedScore)
          ? 'gray'
          : 'yellow';
        const { bg: bgColor, text: textColor } = colorMap[colorGroup];
        return (
          <div key={item.id} className="flex gap-4">
            <div className="flex h-5 w-5 items-center justify-center rounded-full bg-indigo-600 text-xs font-medium text-white">{index + 1}</div>
            <div className="flex-1 space-y-1">
              <h2 className="text-xs font-semibold text-gray-900">{item.question}</h2>
              <div className={cn('flex items-baseline gap-2 rounded-xl p-2 text-xs font-bold', bgColor)}>
                <span className={cn('capitalize', textColor)}>{normalizedScore === 'na' ? 'N/A' : normalizedScore} </span>{' '}
                <span className={textColor}>-</span>
                <p className="font-medium text-gray-600">{item.summary}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
