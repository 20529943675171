import { getDriverData } from 'Utils/summaryGraphFunctions';
import { Topic } from 'src/api/apiTypes/Summary';
import { IChartComparator } from 'src/types/Filter';
import { Metric } from 'src/types/User';
import { getMetricValueAndPercentChange } from './graphFunctions';
import { SOURCE_TYPES } from 'Utils/enums/SourceModelTypes';

export const calculateHighestAndLowestValue = (drivers: Topic[], metric: SOURCE_TYPES, comparator: IChartComparator, isHighestLowest: boolean) => {
  let highestValue = -Infinity;
  let lowestValue = Infinity;

  for (const driver of drivers) {
    const { change } = getMetricValueAndPercentChange(driver?.current_interval.metrics, metric, comparator);

    if (isHighestLowest) {
      const currentValue = driver?.current_interval.metrics[0]?.value;
      const previousValue = driver?.previous_interval.metrics[0]?.value;
      highestValue = Math.max(currentValue, previousValue, highestValue);
      lowestValue = Math.min(currentValue, previousValue, lowestValue);
    } else {
      highestValue = Math.max(Math.abs(change), highestValue);
      lowestValue = Math.min(Math.abs(change), lowestValue);
    }
  }
  return { highestValue, lowestValue };
};

export const getBarGraphData = (
  drivers: Topic[],
  chartComparator: IChartComparator,
  chartMetricObject: Metric,
  tranformDriverDataProps: (p: ReturnType<typeof getDriverData>) => Object,
  transformDataValueProps?: (p: Topic) => Object,
  surveySourceChangeColor?: boolean
) => {
  const data: { name: string; driverData: Topic; [key: string]: any }[] = drivers.map((driver) => ({
    name: driver.name,
    driverData: driver,
    ...tranformDriverDataProps(getDriverData(driver, chartComparator, chartMetricObject.name, true, surveySourceChangeColor)),
    ...(transformDataValueProps ? transformDataValueProps(driver) : {}),
  }));
  const { highestValue, lowestValue } = calculateHighestAndLowestValue(drivers, chartMetricObject.name, chartComparator, !!transformDataValueProps);
  const maxValue = Math.max(Math.abs(highestValue ?? 0), Math.abs(lowestValue ?? 0)) * 0.2;
  let minDomain, maxDomain;
  if (highestValue < 0 || lowestValue < 0) minDomain = `dataMin - ${maxValue}`;
  maxDomain = `dataMax + ${maxValue}`;

  if (chartMetricObject.name === SOURCE_TYPES.SURVEY_TYPE)
    return {
      data,
      minDomain: 0,
      maxDomain: 125,
    };

  return { data, minDomain, maxDomain };
};
