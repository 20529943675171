import React, { FC, memo, useRef, useState } from 'react';
import { useRaf, useWindowSize } from 'react-use';
import { HTMLDivProps } from 'src/types/Globals';
import { cn } from 'Utils/TailwindUtils';

interface ClampledLineProps extends HTMLDivProps {
  text: string;
}

export const ClampledLine: FC<ClampledLineProps> = memo(({ className, text, ...props }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  useRaf();
  useWindowSize();
  const textTruncated = textRef.current?.scrollHeight - textRef.current?.clientHeight > 5;
  if (textTruncated !== showTooltip) setShowTooltip(textTruncated);
  return (
    <span
      className={cn('line-clamp-1', className)}
      ref={textRef}
      data-tooltip-hidden={!showTooltip}
      data-tooltip-content={text}
      data-tooltip-id="tooltip"
      {...props}
      data-tooltip-class-name="whitespace-pre-line max-w-[28rem]"
    >
      {text}
    </span>
  );
});
