import React, { FC } from 'react';
import { AgentProfileCard } from '../AgentProfileCard';
import { FiChevronRight } from 'react-icons/fi';
import { ISetState } from 'src/types/Globals';

export const QAReportHeader: FC<{ agent: string; setCurrentScreen: ISetState<'FullReport' | 'QaReport'>; ticketId: number }> = ({
  agent,
  setCurrentScreen,
  ticketId,
}) => {
  return (
    <div className="flex justify-between">
      <div>
        <h2 className="text-2xl font-bold">Agent QA</h2>
        <AgentProfileCard agent={agent} />
      </div>
      <div className="flex flex-col justify-between py-1.5 text-sm">
        <button
          className="flex gap-1 rounded-lg border border-indigo-600 bg-indigo-25 p-1 px-2 font-semibold text-gray-700"
          onClick={() => setCurrentScreen('FullReport')}
        >
          View full report for <span>{agent?.length <= 18 ? agent : agent.slice(0, 18) + '...'}</span>
          <div className="flex items-center">
            <FiChevronRight className="size-4 text-gray-600" />
          </div>
        </button>
        <span className="text-gray-600">Conversation ID: {ticketId}</span>
      </div>
    </div>
  );
};
