import { FC } from 'react';
import { useAppSelector } from 'src/core/store';
import InboxViewHeader from './InboxViewHeader';
import { motion } from 'framer-motion';
import { RiCloseLine } from 'react-icons/ri';
import type { ConversationData } from '../types';
import { InboxViewMessage } from './InboxViewMessage';
import { cn } from 'Utils/TailwindUtils';

interface InboxViewProps {
  closeConversationInfoView: () => void;
  allTags: string[];
  type: 'survey' | 'support';
  ticket?: ConversationData;
  CSAT?: ConversationData;
  isQuickInsight?: boolean;
}

export const InboxView: FC<InboxViewProps> = ({ closeConversationInfoView, allTags, isQuickInsight, ...rest }) => {
  const selectedConvViewTabId = useAppSelector((state) => state.timeseries.selectedConvViewTabId);
  const messages = rest[selectedConvViewTabId] ? rest[selectedConvViewTabId]?.messages : rest.ticket?.messages;
  return (
    <motion.div
      className={cn('absolute bottom-0 z-10 h-full w-full overflow-hidden border-t bg-white pb-px', { 'border-0': isQuickInsight })}
      initial={{ y: 250 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ y: 250 }}
      data-testid="inbox-conversation-view"
    >
      <div className="h-full w-full overflow-y-auto">
        <button
          className="absolute right-5 top-2 z-10 grid place-items-center rounded-full bg-white/80 p-1.5 text-xl text-grey-700 drop-shadow-md"
          onClick={closeConversationInfoView}
          data-tooltip-content="Close conversation view (Esc)"
          data-tooltip-id="white-tooltip"
          data-tooltip-place="top"
        >
          <RiCloseLine />
        </button>
        <InboxViewHeader allTags={allTags} closeConversationInfoView={closeConversationInfoView} {...rest} />
        <div className="w-full border-b" />
        <InboxViewMessage messages={messages} ticketData={rest.ticket} />
      </div>
    </motion.div>
  );
};
