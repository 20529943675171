import { FreshchatIntegrationForm, FreshdeskIntegrationForm, OkendoIntegrationForm, SalesforceIntegrationForm, TypeformForm } from 'src/types/OAuth';
import { getAdminClientInstance } from './utils/clientAdapters';

export const addHelpScoutCode = async (auth_code: string) => {
  try {
    const key = 'integration/helpscout';
    return getAdminClientInstance(key).post(key, { auth_code });
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addTrustpilotName = async (businessURLs: string[]) => {
  try {
    const key = 'integration/trustpilot';
    const response = await getAdminClientInstance(key).post(key, { businessURLs });
    return { data: response.data, status: response.status };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addUsabillaCode = async (access_key: string, secret_key: string) => {
  try {
    const key = 'integration/usabilla';
    return getAdminClientInstance(key).post(key, { access_key, secret_key });
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const revokeIntegrationAccess = async (key: string) => {
  try {
    return getAdminClientInstance(key).get(key);
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addGooglePlayStoreUrl = async (businessURLs: string[]) => {
  try {
    const key = 'integration/google-play-store';
    const response = await getAdminClientInstance(key).post(key, { urls: businessURLs });
    return { data: response.data, status: response.status };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addAppleAppStoreUrl = async (businessURLs: string[]) => {
  try {
    const key = 'integration/apple-app-store';
    const response = await getAdminClientInstance(key).post(key, { urls: businessURLs });
    return { data: response.data, status: response.status };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addTypeformCode = async (auth_code: string) => {
  try {
    const key = 'integration/typeform';
    return getAdminClientInstance(key).post(key, { auth_code });
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getTypeformList = async () => {
  try {
    const key = 'integration/typeform/forms/select';
    return (await getAdminClientInstance(key).get<{ data: TypeformForm[] }>(key)).data.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addTypeformForms = async (form_ids: string[]) => {
  try {
    const key = 'integration/typeform/forms/store';
    return getAdminClientInstance(key).post(key, { form_ids });
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addFreshdeskForm = async (data: FreshdeskIntegrationForm) => {
  try {
    const key = 'integration/freshdesk';
    const response = await getAdminClientInstance(key).post(key, data);
    return { data: response.data, status: response.status };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addFreshchatForm = async (data: FreshchatIntegrationForm) => {
  try {
    const key = 'integration/freshchat';
    const response = await getAdminClientInstance(key).post(key, data);
    return { data: response.data, status: response.status };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addSalesforceIntegration = async (data: SalesforceIntegrationForm) => {
  try {
    const key = 'integration/salesforce';
    const response = await getAdminClientInstance(key).post(key, data);
    return { data: response.data, status: response.status };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addOkendoIntegration = async (data: OkendoIntegrationForm) => {
  try {
    const key = 'integration/okendo';
    const response = await getAdminClientInstance(key).post(key, data);
    return { data: response.data, status: response.status };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addYotpoAccessToken = async (app_key: string, secret_key: string, grant_type: string) => {
  try {
    const key = 'integration/yotpo';
    return getAdminClientInstance(key).post(key, { app_key, secret_key, grant_type });
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addBazaarVoiceAccessToken = async (api_key: string) => {
  try {
    const key = 'integration/bazaarvoice';
    return getAdminClientInstance(key).post(key, { app_key: api_key });
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addFeefoAccessToken = async (feefo_client_id: string, client_secret: string, email: string, grant_type: string) => {
  try {
    const key = 'integration/feefo';
    return getAdminClientInstance(key).post(key, { feefo_client_id, client_secret, email, grant_type });
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addTrustedshopsAccessToken = async (trustedshops_client_id: string, client_secret: string, email: string, grant_type: string) => {
  try {
    const key = 'integration/trustedshops';
    return getAdminClientInstance(key).post(key, { trustedshops_client_id, client_secret, email, grant_type });
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
