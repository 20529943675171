import React, { useState, useEffect, useRef } from 'react';
import { Avatar } from 'src/components/Avatar';
import { cn } from 'Utils/TailwindUtils';

export const AgentProfileCard = ({ agent, className, showTooltip }: { agent: string; className?: string; showTooltip?: boolean }) => {
  const [maxChars, setMaxChars] = useState(22);
  const textRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 1600) setMaxChars(20);
      else if (width < 1800) setMaxChars(40);
      else setMaxChars(100);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isTruncated = agent.length > maxChars;
  const displayedText = isTruncated ? `${agent.slice(0, maxChars)}...` : agent;

  return (
    <div className={cn('mt-2 flex items-center gap-2 rounded-xl bg-indigo-25 py-2 pl-2.5 pr-10', className)}>
      <Avatar name={agent} type="div" />
      <p
        ref={textRef}
        className="m-0 flex-1 truncate text-lg font-semibold"
        data-tooltip-id="tooltip"
        data-tooltip-content={agent}
        data-tooltip-hidden={!isTruncated || !showTooltip}
      >
        {showTooltip ? displayedText : agent}
      </p>
    </div>
  );
};
