import React, { FC, useEffect, useState } from 'react';
import { useQuickInsight } from './QuickInsightContainer';
import { QuickInsightMetrics } from './Metrics';
import { QuickInsightOverviewGraph } from './OverviewGraph';
import { DrillDown } from './DrillDown';
import { ISelectedClientSourceData, Metric } from 'src/types/User';
import { useQuickSummaryDiginContext } from './QuickSummaryDigInContext';
import { InboxDialog } from './Conversations';
import { ADMIN_INDEX_SOURCE_TYPES, SURVEY_TYPES } from 'Utils/enums/SourceModelTypes';
import { QuickSummary } from './QuickSummary';
import { SingleToggleGroup } from 'src/components/ToggleGroup';
import { useLDFlags } from 'Hooks/useLaunchDakly';
import { Timer } from './Timer';
import { useAppSelector } from 'src/core/store';
import { useFetchQuickSummary } from './useFilterState';
import { useSearchParams } from 'react-router-dom';

export interface InsightOverviewDataProps {
  reportMetric: Metric;
  source: string;
  selectedClientSourceData?: ISelectedClientSourceData;
  navigateToDiscover: () => void;
  sourceType?: ADMIN_INDEX_SOURCE_TYPES;
  setSelectedMetric: (value: Metric) => void;
  isSurveyType?: boolean;
}

export const InsightOverviewData: FC<InsightOverviewDataProps> = (props) => {
  const { summaryNameForPopup, showCustomDiginQuestions } = useLDFlags();
  const { reportMetric, source, selectedClientSourceData, setSelectedMetric, navigateToDiscover } = props;
  const isSurveyType = SURVEY_TYPES.includes(selectedClientSourceData.es_alias.type);
  const [selectedToggle, setSelectedToggle] = useState('conversations');
  const { selectedDimension, dimension } = useQuickInsight();
  const [{ summaryStatus, selectedQuestion }, setDigInState] = useQuickSummaryDiginContext();
  const { selectedDrillDownTopic } = useAppSelector((state) => state.filter);
  const { state, topicPayload } = useQuickInsight();
  const { fetchQuickSummary } = useFetchQuickSummary(setDigInState);

  const [searchParams] = useSearchParams();
  const [timer, setTimer] = useState(0);
  const options = [
    { label: 'Conversations', value: 'conversations' },
    { label: summaryNameForPopup, value: 'dig_in_summary' },
  ];

  const handleValueChange = async (value: string) => {
    if (value === 'dig_in_summary' && summaryStatus === 'idle' && !showCustomDiginQuestions)
      await fetchQuickSummary(
        state,
        selectedDrillDownTopic,
        selectedClientSourceData,
        dimension,
        {
          aspect: searchParams.get('aspect'),
          ...topicPayload,
        },
        'Overall Summary'
      );
    setSelectedToggle(value);
  };
  useEffect(() => {
    if (selectedToggle === 'dig_in_summary' && selectedQuestion)
      fetchQuickSummary(
        state,
        selectedDrillDownTopic,
        selectedClientSourceData,
        dimension,
        { aspect: searchParams.get('aspect'), ...topicPayload },
        selectedQuestion
      );
    if (selectedToggle === 'conversations') setDigInState({ summaryStatus: 'idle', selectedQuestion: undefined });
  }, [selectedDrillDownTopic, searchParams.get('aspect')]);

  return (
    <div className="relative top-0 flex h-[78vh] w-[84vw] justify-between">
      <div className="w-[51%] overflow-y-auto overflow-x-hidden">
        <span className="mx-8 font-sherika text-2xl font-semibold capitalize">{selectedDimension}</span>
        <DrillDown />
        <div className="mb-3.5 ml-8 mr-2 rounded-xl border bg-gray-50 py-2">
          <QuickInsightMetrics reportMetric={reportMetric} setSelectedMetric={setSelectedMetric} isSurveyType={isSurveyType} />
          <QuickInsightOverviewGraph sourceType={selectedClientSourceData.es_alias.type} />
        </div>
      </div>
      <div className="mr-2 w-[46.5%]">
        <SingleToggleGroup
          className="mb-4 mr-5 text-sm text-grey-600"
          options={options}
          optionClassName="radix-state-on:bg-indigo-100 outline w-full outline-gray-200 radix-state-on:outline-indigo-100 radix-state-on:z-10 radix-state-on:text-indigo-600 radix-state-on:hover:bg-indigo-100"
          onValueChange={handleValueChange}
          value={selectedToggle}
          data-testid={selectedToggle === 'conversations' ? '' : 'quick-insight-summary'}
        />
        {summaryStatus === 'pending' && <Timer setTimer={setTimer} />}
        {selectedToggle === 'conversations' ? (
          <InboxDialog navigateToDiscover={navigateToDiscover} source={source} selectedSourceClientData={selectedClientSourceData} />
        ) : (
          <QuickSummary navigateToDiscover={navigateToDiscover} timer={timer} selectedClientSourceData={selectedClientSourceData} />
        )}
      </div>
    </div>
  );
};
