import React, { forwardRef } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { cn } from 'Utils/TailwindUtils';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

interface ConversationAccordionTabProps extends Accordion.AccordionItemProps {
  label?: React.ReactNode;
  icon?: React.ReactNode;
}

export const ConversationAccordionTab = forwardRef<HTMLDivElement, ConversationAccordionTabProps>((props, ref) => {
  const { className, children, label, icon, ...rest } = props;
  return (
    <Accordion.Item className={cn('group space-y-3 rounded-lg p-3', className)} ref={ref} {...rest}>
      <Accordion.Trigger className="flex w-full items-center justify-between text-sm font-bold">
        <div className="flex items-center gap-1.5">
          {icon}
          {label}
        </div>
        <FiChevronDown className="group-radix-state-open:hidden" />
        <FiChevronUp className="group-radix-state-closed:hidden" />
      </Accordion.Trigger>
      <Accordion.Content>{children}</Accordion.Content>
    </Accordion.Item>
  );
});
