import { useMemo } from 'react';
import { CHAT_POSITION_TYPES } from 'Enums/ChatPositionTypes';
import { useAppSelector } from 'src/core/store';

import { IConversation, IMessages } from 'Slices/types/timeseries';
import { ChatHighlighter } from '../ChatHighlighter';
import { ConversationParts } from './ConversationParts';
import { ConversationData } from '../types';

const formChatData = (chats: IMessages[], showConvTranslatedMessage: boolean) => {
  const showOriginalMessage = !showConvTranslatedMessage;
  return chats
    ?.filter((message) => !!message?.author?.role || !!message?.commentType)
    .map((chat) => ({
      index: chat.index,
      position: chat.author.role.trim().toLowerCase() === 'visitor' ? CHAT_POSITION_TYPES.END : CHAT_POSITION_TYPES.START,
      message: showOriginalMessage && chat.translated && chat.originalMessage ? chat.originalMessage : chat.message,
      createdAt: chat.createdAt,
      commentType: chat.commentType,
    }));
};

interface InboxViewMessageProps {
  messages?: IMessages[];
  ticketData?: ConversationData;
}

export const InboxViewMessage: React.FC<InboxViewMessageProps> = ({ messages = [], ...rest }) => {
  const showConvTranslatedMessage = useAppSelector((state) => state.timeseries?.showConvTranslatedMessage);
  const selectedConvViewTabId = useAppSelector((state) => state.timeseries.selectedConvViewTabId);
  const chats = useMemo(() => formChatData(messages, showConvTranslatedMessage), [messages, showConvTranslatedMessage, selectedConvViewTabId]);

  const getMessageText = (messages: IMessages[]) => {
    let chat = messages?.[0];
    if (!showConvTranslatedMessage && chat?.translated && chat?.originalMessage) return chat.originalMessage;
    return chat?.message.toLocaleUpperCase() === 'NO VISITOR COMMENTS' ? '' : chat?.message;
  };

  if (chats === null || chats === undefined || chats.length > 0 || messages[0]?.commentType) {
    return <ConversationParts chats={chats} {...rest} />;
  }
  return (
    <div className="h-full bg-white p-6">
      <div className="break-words text-sm text-grey-1100">
        <ChatHighlighter message={getMessageText(messages)} />
      </div>
    </div>
  );
};
