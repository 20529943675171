import { FC, PropsWithChildren, createContext, useContext, useState } from 'react';
import { IApiCallStatus } from 'src/types/Globals';
import { DigInSummary } from 'src/types/DigIn';

export interface QuickInsightDigInState {
  summary?: DigInSummary;
  summaryStatus: IApiCallStatus;
  summaryError?: string | DigInSummary;
  selectedQuestion?: string;
}

const QuickSummaryDiginContext = createContext<[QuickInsightDigInState, React.Dispatch<React.SetStateAction<QuickInsightDigInState>>] | undefined>(
  undefined
);

export const QuickSummaryDiginContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [digInState, setDigInState] = useState<QuickInsightDigInState>({
    summaryStatus: 'idle',
  });

  return <QuickSummaryDiginContext.Provider value={[digInState, setDigInState]}>{children}</QuickSummaryDiginContext.Provider>;
};

export const useQuickSummaryDiginContext = () => {
  const context = useContext(QuickSummaryDiginContext);
  if (context === null) throw new Error('useQuickSummaryDiginContext must be used within a QuickSummaryDiginContext');
  return context;
};
