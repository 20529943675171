import React, { FC, useEffect, useRef } from 'react';
import { CHAT_POSITION_TYPES } from 'Enums/ChatPositionTypes';
import { cn } from 'Utils/TailwindUtils';
import { ChatHighlighter } from '../ChatHighlighter';
import type { Chat, ConversationData } from '../types';
import * as Accordion from '@radix-ui/react-accordion';
import { ConversationAccordionTab } from './ConversationAccordionTab';
import { HiSparkles, HiOutlineDocumentText } from 'react-icons/hi2';
import { Markdown } from 'src/components/Markdown';
import { useLDFlags } from 'Hooks/useLaunchDakly';
import { AgentQaCard } from './AgentQaCard';
import { useInboxContext } from 'src/ui/containers/QuickInsight/Conversations';

const getChatParts = (chats: Chat[], AnalysisQuestionMap: Map<string, { label: string; qaValue: string }>) => {
  const summary = chats.find(({ commentType }) => ['callSummary', 'ticketSummary'].includes(commentType))?.message;
  const callObjective = chats.find(({ commentType }) => commentType === 'callObjective')?.message;
  const ticketObjective = chats.find(({ commentType }) => commentType === 'ticketObjective')?.message;
  const analysis = chats.filter(({ commentType }) => AnalysisQuestionMap.has(commentType));
  const internalNotes = chats.filter(({ commentType }) => commentType === 'internalNotes');
  const messages = chats.filter(({ commentType }) => !commentType);
  return { summary, analysis, messages, internalNotes, callObjective, ticketObjective };
};

interface ChatProps {
  chats: Chat[];
  ticketData?: ConversationData;
}

export const ConversationParts: FC<ChatProps> = ({ chats = [], ticketData }) => {
  const AnalysisQuestions = useLDFlags().commentTypes;
  const { disableAnalysis, showAgentQaCard, qaFieldNotApplicable } = useLDFlags();
  const { source } = useInboxContext();
  const AnalysisQuestionsObj: Record<string, { label: string; qaValue: string }> = JSON.parse(AnalysisQuestions);
  const AnalysisQuestionMap = new Map(Object.entries(AnalysisQuestionsObj));
  const chatWrapperRef = useRef<HTMLDivElement>();
  const { summary, analysis, messages, internalNotes, callObjective, ticketObjective } = getChatParts(chats, AnalysisQuestionMap);
  useEffect(() => {
    if (chatWrapperRef.current) chatWrapperRef.current.scrollTop = 0;
  }, [chats, chatWrapperRef]);
  const metadata = ticketData?.metadata?.metadata || {};
  const showAgentCard = (metadata?.agent_name || metadata?.Email) && ticketData?.qaPreformanceScore && showAgentQaCard;
  return (
    <div className="space-y-3 divide-y divide-dashed p-3" ref={chatWrapperRef}>
      {showAgentCard && <AgentQaCard ticketData={ticketData} analysis={analysis} />}
      {!showAgentCard && qaFieldNotApplicable && (
        <div className="rounded-lg bg-indigo-50 px-4 py-3 text-sm text-gray-600">Agent QA is not applicable here</div>
      )}
      {(summary || analysis.length > 0 || internalNotes.length > 0) && (
        <Accordion.Root className={cn('space-y-3', { 'pt-3': showAgentQaCard })} type="multiple" defaultValue={['summary', 'internalNotes']}>
          {summary && (
            <ConversationAccordionTab
              className="bg-[#EEECFD] text-[#120560]"
              value="summary"
              label={ticketObjective ? 'Conversation Objective' : callObjective ? 'Call Objective' : 'Summary'}
              icon={<HiSparkles />}
            >
              {callObjective && (
                <>
                  <div className="-mt-2">
                    <ChatHighlighter message={callObjective} className="text-xs text-grey-700" />
                  </div>
                  <div className="mb-1 mt-4 text-sm font-bold">Summary</div>
                </>
              )}
              {ticketObjective && (
                <>
                  <div className="-mt-2">
                    <ChatHighlighter message={ticketObjective} className="text-xs text-grey-700" />
                  </div>
                  <div className="mb-1 mt-4 text-sm font-bold">Summary</div>
                </>
              )}
              <ChatHighlighter message={summary} className="text-xs text-grey-700" />
            </ConversationAccordionTab>
          )}
          {analysis.length > 0 && !disableAnalysis && !showAgentQaCard && (
            <ConversationAccordionTab
              className="bg-[#EBFAFF] text-[#088CB5]"
              value="analysis"
              label={
                <div className="flex gap-2.5">
                  <span>Analysis</span>
                </div>
              }
            >
              <div className="space-y-4">
                {analysis.map(({ commentType, message }, index) => (
                  <div className="flex gap-3" key={commentType}>
                    <span className="text-base font-bold text-grey-900">{index + 1}.</span>
                    <div className="flex flex-col gap-0.5 text-xs text-grey-700">
                      <div className="text-sm font-bold">
                        {AnalysisQuestionMap.get(commentType).label}{' '}
                        <span
                          className={cn(
                            'capitalize !text-gray-600',
                            { '!text-green-700': metadata[AnalysisQuestionMap.get(commentType)?.qaValue] === 'yes' },
                            { '!text-red-800': metadata[AnalysisQuestionMap.get(commentType)?.qaValue] === 'no' },
                            { '!text-blue-800': metadata[AnalysisQuestionMap.get(commentType)?.qaValue] === 'excellent' },
                            { '!text-orange-800': metadata[AnalysisQuestionMap.get(commentType)?.qaValue] === 'poor' }
                          )}
                        >
                          {metadata[AnalysisQuestionMap.get(commentType)?.qaValue] || ''}
                        </span>
                      </div>
                      <ChatHighlighter message={message} />
                    </div>
                  </div>
                ))}
              </div>
            </ConversationAccordionTab>
          )}
          {internalNotes.length > 0 && (
            <ConversationAccordionTab
              className="border border-dashed border-blue-1100 bg-grey-50 text-indigo-600"
              value="internalNotes"
              label="Internal Notes"
              icon={<HiOutlineDocumentText className="stroke-2 text-base" />}
            >
              <div className="space-y-4">
                {internalNotes.map(({ message }, index) => (
                  <Markdown key={index}>{message}</Markdown>
                ))}
              </div>
            </ConversationAccordionTab>
          )}
        </Accordion.Root>
      )}

      <div className="space-y-3 pt-3">
        {messages.map((chat, index) => (
          <div
            key={index}
            className={cn('flex', {
              'justify-start': chat.position === CHAT_POSITION_TYPES.START,
              'justify-end': chat.position === CHAT_POSITION_TYPES.END,
              'mb-16': index == chats.length - 1,
            })}
          >
            <div
              className={cn('min-w-[2.63rem] max-w-[80%] rounded-lg p-3', {
                'rounded-bl-sm bg-grey-50': chat.position === CHAT_POSITION_TYPES.START,
                'rounded-br-sm bg-indigo-50': chat.position === CHAT_POSITION_TYPES.END,
              })}
            >
              <div className="break-words text-xs text-black">
                <ChatHighlighter message={chat.message} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
