import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { cn } from 'Utils/TailwindUtils';
import { updateDrillDownTopic } from 'Slices/filter';
import { capitalize } from 'lodash';
import { FiChevronRight } from 'react-icons/fi';
import { ISetState } from 'src/types/Globals';

interface QuickInsightBreadCrumbProps {
  selectedDimension?: string;
  activeId?: string;
  setActiveId?: ISetState<string>;
  isDigin?: boolean;
}

export const QuickInsightBreadCrumb = ({ selectedDimension, activeId, setActiveId, isDigin }: QuickInsightBreadCrumbProps) => {
  const { selectedDrillDownTopic } = useAppSelector((state) => state.filter);
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const handleBreadCrumbClick = () => {
    if ((!selectedDrillDownTopic && !searchParams.get('aspect')) || isDigin) return;
    if (searchParams.get('aspect')) searchParams.delete('aspect');
    else dispatch(updateDrillDownTopic(null));
    !isDigin && setActiveId(null);
  };
  const dynamicCharacterLimit = isDigin ? 30 : activeId ? 22 : 16;
  return (
    <div className={cn('flex items-center space-x-1 text-sm', { 'my-2': isDigin })}>
      <span
        onClick={handleBreadCrumbClick}
        className={cn('capitalize text-black', {
          'text-gray-500 hover:cursor-pointer hover:text-gray-600 hover:underline': selectedDrillDownTopic || searchParams.get('aspect'),
          'hover:cursor-auto hover:text-gray-500 hover:no-underline': isDigin,
        })}
        data-tooltip-id="tooltip"
        data-tooltip-content={capitalize(selectedDimension)}
        data-tooltip-hidden={
          !selectedDimension || selectedDimension.length <= dynamicCharacterLimit || !(selectedDrillDownTopic || searchParams.get('aspect'))
        }
      >
        {selectedDrillDownTopic || searchParams.get('aspect')
          ? selectedDimension?.length > dynamicCharacterLimit
            ? `${selectedDimension?.slice(0, dynamicCharacterLimit)}...`
            : selectedDimension
          : 'Conversations'}
      </span>
      {(searchParams.get('aspect') || selectedDrillDownTopic?.topic) && (
        <>
          <FiChevronRight className="h-4 w-4" />
          <span
            className="font-medium capitalize"
            data-tooltip-id="tooltip"
            data-tooltip-content={capitalize(selectedDrillDownTopic?.topic)}
            data-tooltip-hidden={selectedDrillDownTopic?.topic?.length <= dynamicCharacterLimit}
          >
            {(selectedDrillDownTopic?.topic?.length || searchParams.get('aspect')?.length) > dynamicCharacterLimit
              ? `${(searchParams.get('aspect') || selectedDrillDownTopic?.topic).slice(0, dynamicCharacterLimit)}...`
              : searchParams.get('aspect') || selectedDrillDownTopic?.topic}
          </span>
        </>
      )}
    </div>
  );
};
